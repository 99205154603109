import React, {Suspense} from "react";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import Loading from 'components/shared/Loading';
import {AUTH_PREFIX_PATH} from 'configs/AppConfig'
import Login from "./Login";
import PasswordReset from "./PasswordReset";
import ISettings from "../../model/interface/ui/ISettings";
import {DirectionType} from "antd/es/config-provider";

interface IProps extends RouteComponentProps<any>{
    settings: ISettings,
    direction: DirectionType
}
export const AuthViews = (viewProps: IProps) => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        {/*<Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`components/auth/Login`))} />*/}
        <Route path={`${AUTH_PREFIX_PATH}/login`} render={(props) => <Login {...props} {...viewProps} /> } />
        <Route path={`${AUTH_PREFIX_PATH}/password-reset/:hash`} render={(props) => <PasswordReset {...viewProps} {...props}/> } />
        {/*<Route path={`${AUTH_PREFIX_PATH}/login-1`} component={lazy(() => import(`./authentication/login-1`))} />*/}
        {/*<Route path={`${AUTH_PREFIX_PATH}/login-2`} component={lazy(() => import(`./authentication/login-2`))} />*/}
        {/*<Route path={`${AUTH_PREFIX_PATH}/register-1`} component={lazy(() => import(`./authentication/register-1`))} />*/}
        {/*<Route path={`${AUTH_PREFIX_PATH}/register-2`} component={lazy(() => import(`./authentication/register-2`))} />*/}
        {/*<Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />*/}
        {/*<Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />*/}
        {/*<Route path={`${AUTH_PREFIX_PATH}/error-2`} component={lazy(() => import(`./errors/error-page-2`))} />*/}
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={{pathname: `${AUTH_PREFIX_PATH}/login`, state: {from: viewProps.location.state?.from}}}/>
      </Switch>
    </Suspense>
  )
}

export default AuthViews;

